html,
body {
  font-family: "Inter", sans-serif;
  color: #3e4e68;
}

.fiscal-year {
  margin: auto 15px;
}

.single-month {
  display: flex;
  justify-content: center;
}

.single-year-month {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.multi-year {
  display: flex;
  //flex-wrap: wrap;
  justify-content: space-around;
}

.multi-year-month {
  display: flex;
  flex-direction: column;
}

.month-container {
  width: 100%;
  flex: 0 1 30%;
  margin-bottom: 35px;
}

.multi-month-name {
  margin: auto 30px;
}

.quarter,
.goto-month-arrow {
  color: #3e4e68;
}

.single-month-name {
  min-width: 175px;
  text-align: center;
  padding: 0 15px;
}

.single-month-switcher {
  display: flex;
}

#prev-month,
#next-month,
.back-to-year {
  &:hover {
    cursor: pointer;
  }
}

.single-year-month .month-container .month-header,
.multi-year-month .month-container .month-header {
  display: flex;
  width: 330px;
  margin: 0 auto;
  justify-content: space-evenly;
}

.single-month .month-container .month-header {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding-right: 40px;
}

.q1 {
  background: #7dcab87a;
}

.q2 {
  background: #f1838f54;
}

.q3 {
  background: #799dcd61;
}

.q4 {
  background: #f6b67038;
}

.week-container,
.day-label-container {
  display: flex;
  min-height: 30px;
  justify-content: center;
}

.week-container,
.day-container,
.day-label-container {
  align-items: center;
}

.week-number,
.logo {
  display: flex;
  min-width: 42px;
  min-height: 42px;
  margin: 0;
  border-right: 1px solid #eff1f5;
  color: #aab2be;
  align-items: center;
  justify-content: center;
}

.single-year-month .day-container,
.multi-year-month .day-container,
.single-year-month .day-label,
.multi-year-month .day-label {
  display: flex;
  min-width: 42px;
  min-height: 42px;
  justify-content: center;
}

.single-month .day-container,
.single-month .day-label {
  min-width: 80px;
  min-height: 80px;
  display: flex;
  justify-content: center;
}

.day-label,
.logo {
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  border: none;
}

.day-container {
  background: #fff;
  cursor: default;
  user-select: none;
}

.inputs {
  display: flex;
  align-items: center;
  min-height: 69px;
  padding: 20px 0;
  max-width: 89%;
  justify-content: space-between;
  margin: 0 auto;
}

.current-week {
  text-align: center;
  margin-bottom: 40px;
  &:hover {
    cursor: pointer;
  }
  .today {
    background-color: #ceefff;
    padding: 11px;
    color: #428bafbf;
    text-transform: uppercase;
    font-size: 0.9em;
    font-weight: bold;
    user-select: none;
  }
  .date {
    background-color: #ceefffa6;
    padding: 10px;
  }
}

.year-arrows {
  display: flex;
}

#prev-year,
#next-year {
  &:hover {
    cursor: pointer;
  }
}

.retailer-buttons {
  margin-bottom: 10px;
}

.multi-button button {
  height: 40px;
  width: 105px;
  font-weight: bold;
  font-size: 1.1rem;
  border: none;
  stroke: black;
  outline: none;
}

button:first-child {
  border-radius: 20px 0 0 20px;
  color: #fff;
  background: #374856;
}

button:nth-child(2) {
  color: #fff;
  background: #374856;
}

button:last-child {
  border-radius: 0 20px 20px 0;
  color: #fff;
  background: #374856;
}

button:hover {
  cursor: pointer;
  filter: drop-shadow(3px 5px 5px rgba(0, 0, 0, 0.45));
}
button:active {
  filter: none;
  transform: scale(0.97);
}

.disabled {
  opacity: 0.4;
}

/* only show 1 month if too small to display 3 */
@media only screen and (max-width: 1070px) {
  .month-container {
    flex: 0 1 auto;
  }
  .multi-year {
    justify-content: flex-start;
  }
}

/* Extra small devices (phones, 500px and down) */
@media only screen and (max-width: 1070px) {
  .inputs {
    flex-direction: column;
  }
  .single-month .day-container,
  .single-month .day-label {
  min-width: 42px;
  min-height: 42px;
  }
}

#footer-iframe {
  width: 100%;
  height: 100%;
  margin-top: 150px;
  overflow: hidden !important;
  iframe {
    width: 100%;
    height: 1200px;
    overflow: hidden !important;
    border: none;
    html,
    body {
      overflow: hidden !important;
    }
  }
}
